<template>
 <div class="th-appwrp">
   <p class="th-appwrp--name">
     <b>
       {{ $t('Product', currentSelectedLanguage) }}
     </b>
     <small>
       {{ `${appName}` }}
     </small>
   </p>
   <p class="th-appwrp--version">
     <b>
       {{ $t('Web App Version', currentSelectedLanguage) }}
     </b>
     <small>
       {{ `${appVersion}` }}
     </small>
   </p>
 </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'webappVersion',
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
  },
};
</script>

<style lang="scss" scoped>
.th-appwrp {
  display: flex;
  flex-direction: column;

  &--name {
    @include font-source($openSans, 12px, rgba(30, 45, 62, 0.6), 600, 15px, 0.3px);

    & > small {
      @include font-source($openSans, 12px, rgba(30, 45, 62, 0.6), normal, 15px, 0.3px);
    }
  }

  &--version {
    @include font-source($openSans, 12px, rgba(30, 45, 62, 0.6), 600, 15px, 0.3px);

    & > small {
      @include font-source($openSans, 12px, rgba(30, 45, 62, 0.6), normal, 15px, 0.3px);
    }
  }
}
</style>
