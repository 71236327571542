<template>
  <div class="th-wrpRnd">
    <span class="th-wrpRnd--round" :style="{backgroundColor: badgeColor}"></span>
    <span v-if="valuePresence" class="th-wrpRnd--text">
      {{ badgeValue }}
    </span>
    <span v-else class="th-wrpRnd--text invisible">
      0
    </span>
  </div>
</template>

<script>
export default {
  name: 'roundLabel',
  props: {
    badgeColor: {
      type: String,
      default: '#3F1333',
    },
    valuePresence: {
      type: Boolean,
      default: true,
    },
    badgeValue: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.th-wrpRnd {
  display: flex;
  flex-direction: row;
  align-items: center;

  &--round {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  &--text {
    @include space($type:margin, $direction:left, $amount:9px);
    @include font-source($openSans, 21px, $primary, 600, 1.21, normal);
  }
}
</style>
