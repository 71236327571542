<template>
  <div class="th-det-wrp">
    <div class="th-det-wrp--page">
      <h2 v-if="pageName == null" class="invisible">
        0
      </h2>
      <h2 v-else>
        {{ pageName }}
      </h2>
    </div>
    <div class="th-det-wrp--drinfo">
      <p v-if="userName == null" class="text-right invisible">
        0
      </p>
      <p v-else class="text-right">
        {{ userName }}
      </p>
      <p v-if="clinicName == null" class="text-right invisible">
        {{ clinicName }}
      </p>
      <p v-else class="text-right">
        {{ clinicName }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'doctorInfoDetailHeader',
  props: {
    pageName: {
      type: String,
      default: 'Default page name',
    },
    userName: {
      type: String,
      default: null,
    },
    clinicName: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.th-det-wrp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include space($type:margin, $direction:bottom, $amount:19px);

  &--page {
    & > h2 {
      @include font-source($openSans, 18px, $secondary, 600, normal, normal);
      @include space($type:margin, $direction:bottom, $amount:0);
      text-transform: capitalize;
    }
  }

  &--drinfo {
    & > p {
      margin-bottom: 0;
      text-transform: capitalize;
      &:first-child {
        @include font-source($openSans, 12px, $secondary, 600, normal, normal);
      }
      &:last-child {
        @include font-source($openSans, 11px, rgba(30, 45, 62, 0.6), 600, normal, normal);
      }
    }
  }
}
</style>
