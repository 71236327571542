<template>
  <div class="th-wrpNmb">
    <p :class="textArrayProps">
      {{ titleProps }}
    </p>
    <p :class="numberArrayProps" class="invisible" v-if="numberProps == null">
      12
    </p>
    <p :class="numberArrayProps" v-else>
      {{ numberProps }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'numberWithText',
  props: {
    titleProps: {
      type: String,
      default: 'Patients',
    },
    numberProps: {
      type: [Number, String],
      default: null,
    },
    numberArrayProps: {
      type: Array,
      default: () => ['th-wrpNmb--number'],
    },
    textArrayProps: {
      type: Array,
      default: () => ['th-wrpNmb--title'],
    },
  },
};
</script>

<style lang="scss" scoped>
.th-wrpNmb {
  display: flex;
  flex-direction: column;
  &--title {
    @include font-source($openSans, 12px, $primary, 600, normal, normal);
    @include space($type:margin, $direction:bottom, $amount:0);
    text-transform: uppercase;
    margin-bottom: 0;
  }
  &--number {
    @include font-source($openSans, 36px, $primary, 600, normal, normal);
    @include space($type:margin, $direction:bottom, $amount:0);
  }
}
</style>
