<template>
  <div class="th-boxlabel">
    <div class="th-boxlabel--text">
      <p>
        {{ labelTitle}}
      </p>
      <p>
        {{ `${labelDesc}  ${$t('Need photo labeled', currentSelectedLanguage)}` }}
      </p>
    </div>
    <div class="th-boxlabel--flag">
      <div v-if="labelFlagDesc">
        {{ $t('Needs labeling', currentSelectedLanguage) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'labelBox',
  props: {
    labelTitle: {
      type: String,
      default: 'Need to label photos',
    },
    labelDesc: {
      type: Number,
      default: 0,
    },
    labelFlagDesc: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
  },
};
</script>

<style lang="scss" scoped>
.th-boxlabel {
  @include space($type:padding, $direction:top, $amount:19px);
  @include space($type:padding, $direction:right, $amount:18px);
  @include space($type:padding, $direction:bottom, $amount:18px);
  @include space($type:padding, $direction:left, $amount:21px);
  -webkit-box-shadow: 4px 7px 6px 4px rgba(232,232,232,1);
  -moz-box-shadow: 4px 7px 6px 4px rgba(232,232,232,1);
  box-shadow: 4px 7px 6px 4px rgba(232,232,232,1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $white;
  @include border-radius(10px);

  &--text {
    @include space($type:margin, $direction:right, $amount:4px);
    & > p:first-child {
      @include font-source($openSans, 16px, $neo-future, 600, normal, normal);
      @include space($type:margin, $direction:bottom, $amount:0);
    }
    & > p:last-child {
      @include font-source($openSans, 11px, rgba(26, 45, 64, 0.6), normal, 1.55, 0.27);
    }
  }

  &--flag {
    & > div {
      text-align: center;
      @include property-color(background, $btc-fancy);
      @include font-source($openSans, 11px, rgba(26, 45, 64, 0.6), normal, 1.55, 0.27);
      @include space($type:padding, $direction:top, $amount:1px);
      @include space($type:padding, $direction:right, $amount:2px);
      @include space($type:padding, $direction:bottom, $amount:1px);
      @include space($type:padding, $direction:left, $amount:2px);
    }
  }
}
</style>
