<template>
  <div class="th-homewrp">
    <page-header
      :userName="userProfile.Name"
      :clinicName="userProfile.ClinicName"
      :pageName="$t('Home', currentSelectedLanguage)"
    />
    <hr class="th-homewrp--hr" />
    <div class="th-homewrp--stat">
      <static-number
        v-if="userInitialData"
        class="th-homewrp--stat-item"
        :number-props="userInitialData.PatientsCount"
        title-props="Patients"
      />
      <static-number
        v-if="userInitialData"
        class="th-homewrp--stat-item"
        :number-props="userInitialData.ScansCount"
        title-props="Scans"
      />
      <static-number
        v-if="userInitialData"
        class="th-homewrp--stat-item"
        :number-props="userInitialData.FlaggedDataCount"
        title-props="Flagged Data" />
    </div>
    <div class="th-homewrp--label">
      <p class="th-homewrp--label-title">
        {{ $t("Patients' amount of labels", currentSelectedLanguage) }}
      </p>
      <div class="th-homewrp--label-labelWrp">
        <static-label
          v-if="userInitialData&&userInitialData.PatintsLabelCount"
          :badge-value="userInitialData.PatintsLabelCount.LabelLaterLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#e5e7e8"
        />
        <static-label
          v-if="userInitialData&&userInitialData.PatintsLabelCount"
          :badge-value="userInitialData.PatintsLabelCount.NoRiskLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#81c8bd"
        />
        <static-label
          v-if="userInitialData&&userInitialData.PatintsLabelCount"
          :badge-value="userInitialData.PatintsLabelCount.MarginalRiskLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#fce180"
        />
        <static-label
          v-if="userInitialData&&userInitialData.PatintsLabelCount"
          :badge-value="userInitialData.PatintsLabelCount.CancerLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#cd6f6f"
        />
      </div>
    </div>
    <div class="th-homewrp--label">
      <p class="th-homewrp--label-title">
        {{ $t("Photos' amount of labels", currentSelectedLanguage) }}
      </p>
      <div class="th-homewrp--label-labelWrp">
        <static-label
          v-if="userInitialData&&userInitialData.PhotosLabelCount"
          :badge-value="userInitialData.PhotosLabelCount.LabelLaterLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#e5e7e8"
        />
        <static-label
          v-if="userInitialData&&userInitialData.PhotosLabelCount"
          :badge-value="userInitialData.PhotosLabelCount.NoRiskLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#81c8bd"
        />
        <static-label
          v-if="userInitialData&&userInitialData.PhotosLabelCount"
          :badge-value="userInitialData.PhotosLabelCount.MarginalRiskLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#fce180"
        />
        <static-label
          v-if="userInitialData&&userInitialData.PhotosLabelCount"
          :badge-value="userInitialData.PhotosLabelCount.CancerLabelsCount"
          class="th-homewrp--label-labelWrp-item"
          badge-color="#cd6f6f"
        />
      </div>
    </div>
    <hr class="th-homewrp--hr" />
    <b-container fluid>
      <b-row>
        <b-col cols="6" md="5" lg="4" xl="3">
          <div class="th-homewrp--lablBoxWrp">
            <box-label
              v-if="userInitialData&&userInitialData.PhotosLabelCount"
              :labelFlagDesc="userInitialData.PhotosLabelCount.LabelLaterLabelsCount !== 0"
              :labelDesc="userInitialData.PhotosLabelCount.LabelLaterLabelsCount"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="th-homewrp--lablAppVersion text-center">
      <app-version-block />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import pageHeader from '../../../../../components/moleculs/dashboard/doctor/pageHead/doctorInfoDetailHeader.vue';
import staticNumber from '../../../../../components/atoms/numberWithText.vue';
import staticLabel from '../../../../../components/atoms/roundLabel.vue';
import boxLabel from '../../../../../components/moleculs/labelBox.vue';
import appVersionBlock from '../../../../../components/atoms/webappVersion.vue';
import { GET_DOCTOR_PATIENTS_SCANS_FLAGGEDDATA } from '../../../../../store/modules/profile.module';
import { SET_CURRENT_PAGE } from '../../../../../store/modules/patients.module';

export default {
  name: 'doctor-homePage',
  components: {
    pageHeader,
    staticNumber,
    staticLabel,
    boxLabel,
    appVersionBlock,
  },
  mounted() {
    this.$store.dispatch(GET_DOCTOR_PATIENTS_SCANS_FLAGGEDDATA);
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters({
      userProfile: 'getProfile',
      userInitialData: 'getProfileInitData',
    }),
  },
  // watch: {
  //   userInitialData(val) {
  //     console.log(val);
  //   },
  // },
  beforeDestroy() {
    this.$store.dispatch(SET_CURRENT_PAGE, null);
  },
};
</script>

<style lang="scss" scoped>
.th-homewrp {
  @include space($type:padding, $direction:top, $amount:36px);
  @include space($type:padding, $direction:right, $amount:32px);
  @include space($type:padding, $direction:bottom, $amount:34px);
  @include space($type:padding, $direction:left, $amount:30px);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $jessy-jay;

  &--hr {
    @include border(1px, solid, rgba(30, 45, 62, 0.1), all);
    @include space($type:margin, $direction:top, $amount:0);
    @include space($type:margin, $direction:bottom, $amount:42px);
    width: 100%;
  }

  &--stat {
    display: flex;
    flex-direction: row;
    @include space($type:margin, $direction:bottom, $amount:38px);

    &-item:not(:first-child) {
      @include space($type:margin, $direction:left, $amount:118px);
    }
  }

  &--label {
    @include space($type:margin, $direction:bottom, $amount:42px);
    &-title {
      @include font-source($openSans, 12px, $primary, 600, normal, normal);
      @include space($type:margin, $direction:bottom, $amount:16px);
      text-transform: uppercase;
    }
    &-labelWrp {
      display: flex;
      flex-direction: row;

      &-item:not(:first-child) {
        @include space($type:margin, $direction:left, $amount:54px);
      }
    }
  }

  &--lablAppVersion {
    @include space($type:margin, $direction:top, $amount:auto);
  }
}
</style>
